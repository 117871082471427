/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { ISortParam } from 'app/components/list-view/interfaces/sort-param.interface';
import { nonDomainRelatedRoles, UserRole } from 'app/enums/user-role.enum';
import { UserStatus } from 'app/enums/user-status.enum';
import { IPage } from 'app/interfaces/page.interface';
import { ISelectInterface2 } from 'app/interfaces/select.interface';
import { CountryProfile } from 'app/models/country-profile.model';
import { Domain } from 'app/models/domain.model';
import { UserDomain } from 'app/models/user-domain.model';
import { User } from 'app/models/user.model';
import { Datastore } from 'app/services/datastore/datastore.service';
import { DomainService } from 'app/services/domain/domain.service';
import { contains } from 'app/utils/helpers/contains/contains.helper';
import { intersection, removeDuplicates, transformSortParams } from 'app/utils/helpers/helpers';
import { Observable, Subject } from 'rxjs';
import { getSelectOptionsFromArray } from '../../utils/helpers/select/select.helper';

@Injectable()
export class UserService {
	constructor(
		private readonly datastore: Datastore,
		private readonly domainService: DomainService,
	) {}

	public search({ filter = {}, page = {}, sort = [] }): Observable<JsonApiQueryData<User>> {
		const include: Array<string> = ['userDomains', 'userDomains.domain'];

		const sortParam: Array<ISortParam> = [].concat(sort);

		const sortParamString: Array<string> = transformSortParams(sortParam);

		return this.datastore.findAll(User, {
			filter,
			page,
			include: include.join(','),
			sort: sortParamString.join(','),
		});
	}

	public searchUserByUsername(username: string): Observable<User> {
		const user$: Subject<User> = new Subject<User>();

		this.search({ filter: { text: username } }).subscribe((queryData: JsonApiQueryData<User>) => {
			const users: Array<User> = queryData.getModels();

			const foundUser = users.find((user: User) => user.username === username.toLowerCase());

			user$.next(foundUser);
		});

		return user$;
	}

	public getUser(id: string, includeRelationships: Array<string> = []): Observable<User> {
		return this.datastore.findRecord(User, id, {
			include: includeRelationships.join(','),
		});
	}

	public createUser(userData: object = {}): User {
		return this.datastore.createRecord(User, userData);
	}

	public deleteUser(user: User): Observable<any> {
		return this.datastore.deleteRecord(User, user.id);
	}

	public getUserRoles(user: User): Array<UserRole> {
		const roles: Array<UserRole> = user.roles || [];

		const domainRelatedRoles: Array<UserRole> = this.getUserDomains(user).map(
			(userDomain: UserDomain) => userDomain.role,
		);

		return removeDuplicates(roles.concat(domainRelatedRoles));
	}

	public getUserDomains(user: User): Array<UserDomain> {
		return user.userDomains || [];
	}

	public getUsersDomains(user: User, permittedRoles?: Array<UserRole>): Array<Domain> {
		const roles: Array<UserRole> = permittedRoles
			? intersection(this.getUserRoles(user), permittedRoles)
			: this.getUserRoles(user);

		const hasNonDomainRelatedRoles: boolean = intersection(nonDomainRelatedRoles, roles).length > 0;

		const userDomains: Array<Domain> = this.getUserDomains(user)
			.filter(({ role }) => contains(roles, role))
			.map(({ domain }) => domain);

		return hasNonDomainRelatedRoles ? this.domainService.domains : removeDuplicates(userDomains);
	}

	public getUsersCountryProfiles(
		user: User,
		permittedRoles: Array<UserRole> = null,
	): Array<CountryProfile> {
		return this.getUsersDomains(user, permittedRoles).reduce(
			(countryProfiles: Array<CountryProfile>, domain: Domain) => {
				if (domain.countryProfiles instanceof Array) {
					return countryProfiles.concat(
						domain.countryProfiles.filter((countryProfile) => Boolean(countryProfile)),
					);
				}
				return countryProfiles;
			},
			[],
		);
	}

	public getUsersCountryProfileOptions(
		user: User,
		permittedRoles: Array<UserRole> = null,
	): Array<ISelectInterface2<{ id: string }>> {
		return getSelectOptionsFromArray(this.getUsersCountryProfiles(user, permittedRoles), {
			getLabel: (cp: CountryProfile) => cp.name,
			sortByLabel: true,
			metaItemName: 'countryProfile',
		});
	}

	private checkUserRole(user: User, role: UserRole): boolean {
		const userRoles: Array<UserRole> = this.getUserRoles(user);
		return contains(userRoles, role);
	}

	public isUserGlobalContentManager(user: User): boolean {
		return this.checkUserRole(user, UserRole.GLOBAL_CONTENT_MANAGER);
	}

	public isUserSystemAdministrator(user: User): boolean {
		return this.checkUserRole(user, UserRole.SYSTEM_ADMINISTRATOR);
	}

	public isUserContentManager(user: User): boolean {
		return this.checkUserRole(user, UserRole.CONTENT_MANAGER);
	}

	public isReadOnlyUser(user: User): boolean {
		return this.checkUserRole(user, UserRole.READ_ONLY);
	}

	public isUserSysAdminOrGlobalCM(user: User): boolean {
		return this.isUserSystemAdministrator(user) || this.isUserGlobalContentManager(user);
	}

	public isUserActive(user: User): boolean {
		return user.status === UserStatus.ACTIVE;
	}

	public getUsers(
		filter: object = {},
		page: IPage = { size: 100, number: 0 },
	): Observable<Array<User>> {
		const users$: Subject<Array<User>> = new Subject<Array<User>>();

		this.datastore
			.findAll(User, {
				filter,
				page,
			})
			.subscribe((data) => {
				users$.next(data.getModels());
			});

		return users$;
	}
}
