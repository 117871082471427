/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { JsonApiQueryData } from 'angular2-jsonapi';
import { DomainId } from 'app/enums/domain-id.enum';
import { Domain } from 'app/models/domain.model';
import { Datastore } from 'app/services/datastore/datastore.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DomainService {
	private cachedDomains: Array<Domain>;

	constructor(private readonly datastore: Datastore) {}

	public initDomains(): Observable<Array<Domain>> {
		const includes = ['countryProfiles', 'countryProfiles.countryInfo'];

		return this.fetchDomains(includes).pipe(
			tap((domains: Array<Domain>) => {
				this.domains = domains;
			}),
		);
	}

	get domains(): Array<Domain> {
		return this.cachedDomains;
	}

	set domains(domains: Array<Domain>) {
		this.cachedDomains = domains;
	}

	public get globalDomain(): Domain {
		return this.domains.find((domain: Domain) => domain.name === DomainId.GLOBAL);
	}

	// Domains are something that is not changed often, therefore, it's
	// enough to fetch them only once
	public fetchDomains(includes: Array<string> = []): Observable<Array<Domain>> {
		const domains$: Subject<Array<Domain>> = new Subject();

		this.datastore
			.findAll(Domain, {
				include: includes.join(','),
			})
			.subscribe((domainsData: JsonApiQueryData<Domain>) => {
				domains$.next(domainsData.getModels());
			});

		return domains$;
	}
}
