/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'CountryProfile',
	endpoint: 'Profile',
})
export class CountryProfile extends HalDatastoreModel {}
